import React from 'react'
import ReactDOM from 'react-dom/client'
//import './index.css'
import './index.css'
import 'animate.css'
import {Link} from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowsUpDownLeftRight, faBorderNone, faRefresh } from '@fortawesome/free-solid-svg-icons'


{/*grab pergola images and populate array with image paths*/}
function importAll(r) {
    let images = {};
    r.keys().map((item) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('./images', false, /\.(png|jpg|svg)$/));

const lumberPrices = {
    _2208: 4.79,
    _2210: 5.85,
    _2212: 10.78,
    _2812: 17.95,
    _2814: 21.01,
    _2816: 21.74,
    _2820: 27.18,
    _4408: 11.01,
    _4410: 13.76,
    _4608: 17.13,
    _4812: 49.44,
    _4814: 49.44,
    _4816: 56.51,
    _4820: 70.63,
    _6608: 37.44
}

const hardwarePrices = {
    _12ftPostDouble: 700.00,
    _12ftPostSingle: 900.00,
    _12ftLoproDouble: 760.00,
    _12ftLoproSingle: 960.00,

    _16ftPostDouble: 870.00,
    _16ftPostSingle: 1170.00,
    _16ftLoproDouble: 950.00,
    _16ftLoproSingle: 1250.00,

    _20ftPostDouble: 1140.00,
    _20ftPostSingle: 1440.00,
    _20ftLoproDouble: 1240.00,
    _20ftLoproSingle: 1540.00,

    _24ftPostDouble: 1280.00,
    _24ftPostSingle: 1600.00,
    _24ftLoproDouble: 1300.00,
    _24ftLoproSingle: 1620.00,

    _32ftPostDouble: 1690.00,
    _32ftPostSingle: 2090.00,
    _32ftLoproDouble: 1850.00,
    _32ftLoproSingle: 2250.00,

    _40ftPostDouble: 2040.00,
    _40ftPostSingle: 2440.00,
    _40ftLoproDouble: 2240.00,
    _40ftLoproSingle: 2640.00
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hardwareType: 'post',
            postType: 'db',
            pergolaWidth: '12w',
            pergolaDepth: '8',
            rafterSpacing: '16oc',
            step: 0,
            postsC: "",
            groundBeamC: "",
            roofBeamC: "",
            raftersC: "",
            purlinsC: "",
            fourByFourC: ""
        }
    }

    radioChangeHandlerW = (event) => {
        this.setState({
            pergolaWidth: event.target.value,
            step: (this.state.step > 3 ? this.state.step : 3)
        });
    }
    
    radioChangeHandlerD = (event) => {
        this.setState({
            pergolaDepth: event.target.value
        });
    }

    radioChangeHandlerT = (event) => {
        this.setState({
            hardwareType: event.target.value,
            step: (this.state.step > 1 ? this.state.step : 1)
        });
    }

    radioChangeHandlerS = (event) => {
        this.setState({
            rafterSpacing: event.target.value
        });
    }

    radioChangeHandlerP = (event) => {
        this.setState({
            postType: event.target.value,
            step: (this.state.step > 2 ? this.state.step : 2)
        });
    }

    radioChangeHandlerReset = (event) => {
        window.location.reload(false);
    }



    radioChangeHandlerPostCost = (event) => {
        this.setState({
            postsC: event.target.value
        });
    }

    radioChangeHandlerGroundBeamCost = (event) => {
        this.setState({
            groundBeamC: event.target.value
        });
    }

    radioChangeHandlerRoofBeamCost = (event) => {
        this.setState({
            roofBeamC: event.target.value
        });
    }

    radioChangeHandlerRafterCost = (event) => {
        this.setState({
            raftersC: event.target.value
        });
    }

    radioChangeHandlerPurlinCost = (event) => {
        this.setState({
            purlinsC: event.target.value
        });
    }

    radioChangeHandlerFourByFourCost = (event) => {
        this.setState({
            fourByFourC: event.target.value
        });
    }


    render() {

        var imageURL;

        switch (this.state.step) {
            case 0:
                imageURL = 'house.jpg';
                break;
            
            case 1:
                imageURL = this.state.hardwareType + '.jpg';
                break;
            
            case 2:
                imageURL = this.state.hardwareType + '_' + this.state.postType + '.jpg';
                break;
            
            default:
                imageURL = this.state.hardwareType + '_' + this.state.pergolaWidth + '_' + this.state.pergolaDepth + '_' + this.state.rafterSpacing + '_' + this.state.postType + '.jpg';
                break;
        }

        var hardwareURL;
        var totalHardwareCost;

        var hardware = {
            risers: 0,
            postBolts: 0,
            lags: 0,
            bridgeWashers: 0,
            postBases: 0,
            TBraces: 0
        };

        var lumber = {
            posts: 0,
            roofBeam: (this.state.hardwareType === 'post') ? 2 : 1,
            groundBeam: (this.state.postType === 'db') ? 2 : 1,
            rafters: 0,
            purlins: 0,
            fourByFour: 0,

            postLength: 0,
            beamLength: 0,
            rafterLength: 0,
            purlinLength: 0,

            postCost: (this.state.postType === 'db') ? lumberPrices._6608 : lumberPrices._4608,
            roofBeamCost: 0,
            groundBeamCost: 0,
            rafterCost: 0,
            purlinCost: 0,
            fourByFourCost: lumberPrices._4408
        };


        function getPostBolts(hardwareType, postType, risers, posts)
        {
            var postBolts;

            if(hardwareType === 'post')
                postBolts = (postType === 'db') ? ((risers * 2) + (posts * 4)) : ((risers * 2) + (posts * 6));
            else
                postBolts = (postType === 'db') ? (posts * 4) : (posts * 6);
                
            return postBolts;
        }

        function getBridgeWashers(hardwareType, postType, risers, posts)
        {
            var bridgeWashers;

            if(hardwareType === 'post')
                bridgeWashers = (postType === 'db') ? ((risers * 4) + (posts * 10)) : ((risers * 4) + (posts * 6));
            else
                bridgeWashers = (postType === 'db') ? (posts * 10) : (posts * 6);
                
            return bridgeWashers;
        }

        switch (this.state.pergolaWidth) {
            case '12w':
                {/*Lumber*/}
                lumber.posts = 2;
                lumber.rafters = (this.state.rafterSpacing === '16oc') ? 10 : 6;

                lumber.purlinLength = 13;
                lumber.beamLength = 14;

                if (this.state.postType === 'db' && this.state.hardwareType === 'post')
                    lumber.fourByFour = 2;
                else if (this.state.postType === 'db')
                    lumber.fourByFour = 2;
                else if (this.state.hardwareType === 'post')
                    lumber.fourByFour = 1;

                {/*Hardware*/}
                hardware.risers = 3;
                hardware.postBolts = getPostBolts(this.state.hardwareType, this.state.postType, hardware.risers, lumber.posts);
                hardware.TBraces = 2;
                hardware.lags = 4;
                hardware.bridgeWashers = getBridgeWashers(this.state.hardwareType, this.state.postType, hardware.risers, lumber.posts);
                hardware.postBases = 2;
                hardwareURL = 'https://www.patioroofriser.com/product-page/patio-roof-package-12-foot';
                if(this.state.hardwareType === 'post')
                    totalHardwareCost = (this.state.postType === 'db') ? hardwarePrices._12ftPostDouble : hardwarePrices._12ftPostSingle;
                else
                    totalHardwareCost = (this.state.postType === 'db') ? hardwarePrices._12ftLoproDouble : hardwarePrices._12ftLoproSingle;

                break;

            case '16w':
                {/*Lumber*/}
                lumber.posts = (this.state.postType === 'db') ? 2 : 3;
                lumber.rafters = (this.state.rafterSpacing === '16oc') ? 13 : 8;

                lumber.purlinLength = 17;
                lumber.beamLength = 18;

                if (this.state.postType === 'db' && this.state.hardwareType === 'post')
                    lumber.fourByFour = 3;
                else if (this.state.postType === 'db')
                    lumber.fourByFour = 2;
                else if (this.state.hardwareType === 'post')
                    lumber.fourByFour = 1;

                {/*Hardware*/}
                hardware.risers = 4;
                hardware.postBolts = getPostBolts(this.state.hardwareType, this.state.postType, hardware.risers, lumber.posts);
                hardware.TBraces = 3;
                hardware.lags = 4;
                hardware.bridgeWashers = getBridgeWashers(this.state.hardwareType, this.state.postType, hardware.risers, lumber.posts);
                hardware.postBases = (this.state.postType === 'db') ? 2 : 3;
                hardwareURL = 'https://www.patioroofriser.com/product-page/patio-roof-package-16-foot';
                if(this.state.hardwareType === 'post')
                    totalHardwareCost = (this.state.postType === 'db') ? hardwarePrices._16ftPostDouble : hardwarePrices._16ftPostSingle;
                else
                    totalHardwareCost = (this.state.postType === 'db') ? hardwarePrices._16ftLoproDouble : hardwarePrices._16ftLoproSingle;


                break;

            case '20w':
                {/*Lumber*/}
                lumber.posts = 3;
                lumber.rafters = (this.state.rafterSpacing === '16oc') ? 16 : 10;

                lumber.purlinLength = 21;
                lumber.beamLength = 22;

                if (this.state.postType === 'db' && this.state.hardwareType === 'post')
                    lumber.fourByFour = 3;
                else if (this.state.postType === 'db')
                    lumber.fourByFour = 3;
                else if (this.state.hardwareType === 'post')
                    lumber.fourByFour = 1;

                {/*Hardware*/}
                hardware.risers = 5;
                hardware.postBolts = getPostBolts(this.state.hardwareType, this.state.postType, hardware.risers, lumber.posts);
                hardware.lags = 6;
                hardware.TBraces = 3;
                hardware.bridgeWashers = getBridgeWashers(this.state.hardwareType, this.state.postType, hardware.risers, lumber.posts);
                hardware.postBases = 3;
                hardwareURL = 'https://www.patioroofriser.com/product-page/patio-roof-package-20-foot';
                if(this.state.hardwareType === 'post')
                    totalHardwareCost = (this.state.postType === 'db') ? hardwarePrices._20ftPostDouble : hardwarePrices._20ftPostSingle;
                else
                    totalHardwareCost = (this.state.postType === 'db') ? hardwarePrices._20ftLoproDouble : hardwarePrices._20ftLoproSingle;                


                break;

            case '24w':
                {/*Lumber*/}
                lumber.posts = 3;
                lumber.rafters = (this.state.rafterSpacing === '16oc') ? 19 : 12;

                lumber.purlinLength = 25;
                lumber.beamLength = 26;

                if (this.state.postType === 'db' && this.state.hardwareType === 'post')
                    lumber.fourByFour = 4;
                else if (this.state.postType === 'db')
                    lumber.fourByFour = 3;
                else if (this.state.hardwareType === 'post')
                    lumber.fourByFour = 1;
                
                {/*Hardware*/}
                hardware.risers = 6;
                hardware.postBolts = getPostBolts(this.state.hardwareType, this.state.postType, hardware.risers, lumber.posts);
                hardware.lags = 6;
                hardware.TBraces = 3;
                hardware.bridgeWashers = getBridgeWashers(this.state.hardwareType, this.state.postType, hardware.risers, lumber.posts);
                hardware.postBases = 3;
                hardwareURL = 'https://www.patioroofriser.com/product-page/patio-roof-package-24-foot';
                if(this.state.hardwareType === 'post')
                    totalHardwareCost = (this.state.postType === 'db') ? hardwarePrices._24ftPostDouble : hardwarePrices._24ftPostSingle;
                else
                    totalHardwareCost = (this.state.postType === 'db') ? hardwarePrices._24ftLoproDouble : hardwarePrices._24ftLoproSingle;                


                break;

            case '32w':
                {/*Lumber*/}
                lumber.posts = (this.state.postType === 'db') ? 3 : 4;
                lumber.rafters = (this.state.rafterSpacing === '16oc') ? 25 : 16;

                lumber.purlinLength = 33;
                lumber.beamLength = 34;

                if (this.state.postType === 'db' && this.state.hardwareType === 'post')
                    lumber.fourByFour = 4;
                else if (this.state.postType === 'db')
                    lumber.fourByFour = 3;
                else if (this.state.hardwareType === 'post')
                    lumber.fourByFour = 2;
                
                {/*Hardware*/}
                hardware.risers = 8;
                hardware.postBolts = getPostBolts(this.state.hardwareType, this.state.postType, hardware.risers, lumber.posts);
                hardware.lags = 6;
                hardware.TBraces = (this.state.postType === 'db') ? 3 : 4;
                hardware.bridgeWashers = getBridgeWashers(this.state.hardwareType, this.state.postType, hardware.risers, lumber.posts);
                hardware.postBases = (this.state.postType === 'db') ? 3 : 4;
                hardwareURL = 'https://www.patioroofriser.com/product-page/patio-roof-package-32-foot';
                if(this.state.hardwareType === 'post')
                    totalHardwareCost = (this.state.postType === 'db') ? hardwarePrices._32ftPostDouble : hardwarePrices._32ftPostSingle;
                else
                    totalHardwareCost = (this.state.postType === 'db') ? hardwarePrices._32ftLoproDouble : hardwarePrices._32ftLoproSingle;                


                break;

            case '40w':
                {/*Lumber*/}
                lumber.posts = (this.state.postType === 'db') ? 3 : 4;
                lumber.rafters = (this.state.rafterSpacing === '16oc') ? 31 : 20;

                lumber.purlinLength = 41;
                lumber.beamLength = 42;

                if (this.state.postType === 'db' && this.state.hardwareType === 'post')
                    lumber.fourByFour = 4;
                else if (this.state.postType === 'db')
                    lumber.fourByFour = 3;
                else if (this.state.hardwareType === 'post')
                    lumber.fourByFour = 2;

                {/*Hardware*/}
                hardware.risers = 10;
                hardware.postBolts = getPostBolts(this.state.hardwareType, this.state.postType, hardware.risers, lumber.posts);
                hardware.lags = 6;
                hardware.TBraces = (this.state.postType === 'db') ? 3 : 4;
                hardware.bridgeWashers = getBridgeWashers(this.state.hardwareType, this.state.postType, hardware.risers, lumber.posts);
                hardware.postBases = (this.state.postType === 'db') ? 3 : 4;
                hardwareURL = 'https://www.patioroofriser.com/product-page/patio-roof-package-40-foot';
                if(this.state.hardwareType === 'post')
                    totalHardwareCost = (this.state.postType === 'db') ? hardwarePrices._40ftPostDouble : hardwarePrices._40ftPostSingle;
                else
                    totalHardwareCost = (this.state.postType === 'db') ? hardwarePrices._40ftLoproDouble : hardwarePrices._40ftLoproSingle;                


                break;
                
            default:
                break; 
        }

        switch (this.state.pergolaDepth) {
            case '8':
                {/*Lumber*/}
                lumber.purlins = (this.state.rafterSpacing === '16oc') ? 11 : 8;

                lumber.rafterLength = 12;
                lumber.rafterCost = lumberPrices._2812;

                break;

            case '10':
                {/*Lumber*/}
                lumber.purlins = (this.state.rafterSpacing === '16oc') ? 13 : 10;

                lumber.rafterLength = 14;
                lumber.rafterCost = lumberPrices._2814;

                break;
            
            case '12':
                {/*Lumber*/}
                lumber.purlins = (this.state.rafterSpacing === '16oc') ? 15 : 11;

                lumber.rafterLength = 16;
                lumber.rafterCost = lumberPrices._2816;

                break;

            case '14':
                {/*Lumber*/}
                lumber.purlins = (this.state.rafterSpacing === '16oc') ? 17 : 13;

                lumber.rafterLength = 20;
                lumber.rafterCost = lumberPrices._2820;
                
                break;
            
            case '16':
                {/*Lumber*/}
                lumber.purlins = (this.state.rafterSpacing === '16oc') ? 19 : 14;

                lumber.rafterLength = 20;
                lumber.rafterCost = lumberPrices._2820;

                break;
            
            default: 
                break;
        }


        {/*adjusts lumber sizes to the next closest standard size and assigns price*/}
        if(lumber.beamLength > 12 && lumber.beamLength <= 14)
        {
            lumber.beamLength = 14;
            lumber.roofBeamCost = (this.state.hardwareType === 'post') ? lumberPrices._2814 : lumberPrices._4814;
            lumber.groundBeamCost = (this.state.postType === 'db') ? lumberPrices._2814 : lumberPrices._4814;
        }
        if(lumber.beamLength > 14 && lumber.beamLength <= 16)
        {
            lumber.beamLength = 16;
            lumber.roofBeamCost = (this.state.hardwareType === 'post') ? lumberPrices._2816 : lumberPrices._4816;
            lumber.groundBeamCost = (this.state.postType === 'db') ? lumberPrices._2816 : lumberPrices._4816;
        }
        if(lumber.beamLength > 16 && lumber.beamLength <= 20)
        {
            lumber.beamLength = 20;  
            lumber.roofBeamCost = (this.state.hardwareType === 'post') ? lumberPrices._2820 : lumberPrices._4820;
            lumber.groundBeamCost = (this.state.postType === 'db') ? lumberPrices._2820 : lumberPrices._4820;
        }   
        if(lumber.beamLength > 20 && lumber.beamLength <= 24)
        {
            lumber.beamLength = 12;
            lumber.groundBeam = lumber.groundBeam * 2;
            lumber.roofBeam = lumber.roofBeam * 2;
            lumber.roofBeamCost = (this.state.hardwareType === 'post') ? lumberPrices._2812 : lumberPrices._4812;
            lumber.groundBeamCost = (this.state.postType === 'db') ? lumberPrices._2812 : lumberPrices._4812;
        }
        if(lumber.beamLength > 24 && lumber.beamLength <= 28)
        {
            lumber.beamLength = 14;
            lumber.groundBeam = lumber.groundBeam * 2;
            lumber.roofBeam = lumber.roofBeam * 2;
            lumber.roofBeamCost = (this.state.hardwareType === 'post') ? lumberPrices._2814 : lumberPrices._4814;
            lumber.groundBeamCost = (this.state.postType === 'db') ? lumberPrices._2814 : lumberPrices._4814;
        }
        if(lumber.beamLength > 28 && lumber.beamLength <= 32)
        {
            lumber.beamLength = 16;
            lumber.groundBeam = lumber.groundBeam * 2;
            lumber.roofBeam = lumber.roofBeam * 2;
            lumber.roofBeamCost = (this.state.hardwareType === 'post') ? lumberPrices._2816 : lumberPrices._4816;
            lumber.groundBeamCost = (this.state.postType === 'db') ? lumberPrices._2816 : lumberPrices._4816;
        }     
        if(lumber.beamLength > 32 && lumber.beamLength <= 36)
        {
            lumber.beamLength = 12;
            lumber.groundBeam = lumber.groundBeam * 3;
            lumber.roofBeam = lumber.roofBeam * 3;
            lumber.roofBeamCost = (this.state.hardwareType === 'post') ? lumberPrices._2812 : lumberPrices._4812;
            lumber.groundBeamCost = (this.state.postType === 'db') ? lumberPrices._2812 : lumberPrices._4812;
        }     
        if(lumber.beamLength > 36 && lumber.beamLength <= 42)
        {
            lumber.beamLength = 14;
            lumber.groundBeam = lumber.groundBeam * 3;
            lumber.roofBeam = lumber.roofBeam * 3;
            lumber.roofBeamCost = (this.state.hardwareType === 'post') ? lumberPrices._2814 : lumberPrices._4814;
            lumber.groundBeamCost = (this.state.postType === 'db') ? lumberPrices._2814 : lumberPrices._4814;
        }

        
        if(lumber.purlinLength > 12 && lumber.purlinLength <= 16)
        {
           lumber.purlinLength = 8;
           lumber.purlins = lumber.purlins * 2;
           lumber.purlinCost = lumberPrices._2208;
        }
        if(lumber.purlinLength > 16 && lumber.purlinLength <= 20)
        {
           lumber.purlinLength = 10;
           lumber.purlins = lumber.purlins * 2;
           lumber.purlinCost = lumberPrices._2210;
        }
        if(lumber.purlinLength > 20 && lumber.purlinLength <= 24)
        {
           lumber.purlinLength = 8;
           lumber.purlins = lumber.purlins * 3;
           lumber.purlinCost = lumberPrices._2208;
        }
        if(lumber.purlinLength > 24 && lumber.purlinLength <= 30)
        {
           lumber.purlinLength = 10;
           lumber.purlins = lumber.purlins * 3;
           lumber.purlinCost = lumberPrices._2210;
        }
        if(lumber.purlinLength > 30 && lumber.purlinLength <= 32)
        {
           lumber.purlinLength = 8;
           lumber.purlins = lumber.purlins * 4;
           lumber.purlinCost = lumberPrices._2208;
        }
        if(lumber.purlinLength > 32 && lumber.purlinLength <= 40)
        {
           lumber.purlinLength = 10;
           lumber.purlins = lumber.purlins * 4;
           lumber.purlinCost = lumberPrices._2210;
        }
        if(lumber.purlinLength > 40 && lumber.purlinLength <= 48)
        {
           lumber.purlinLength = 8;
           lumber.purlins = lumber.purlins * 5;
           lumber.purlinCost = lumberPrices._2208;
        }


        var totalLumberCost =     (((this.state.postsC === "") ? lumber.postCost : this.state.postsC) * lumber.posts)
                                + (((this.state.raftersC === "") ? lumber.rafterCost : this.state.raftersC) * lumber.rafters)
                                + (((this.state.groundBeamC === "") ? lumber.groundBeamCost : this.state.groundBeamC) * lumber.groundBeam)
                                + (((this.state.roofBeamC === "") ? lumber.roofBeamCost : this.state.roofBeamC) * lumber.roofBeam)
                                + (((this.state.purlinsC === "") ? lumber.purlinCost : this.state.purlinsC) * lumber.purlins)
                                + (((this.state.fourByFourC === "") ? lumber.fourByFourCost : this.state.fourByFourC) * lumber.fourByFour);


        return (
            <div>
                <header>
                    <a href="https://www.patioroofriser.com" target="_blank" rel="noopener" title="Patio Roof Riser"><img className="logo" src="./logo-prr.svg" alt="Patio Roof Riser Logo"/></a>
                    <div className='head-wrap'>
                        <h1>PATIO ROOF RISER<br/>PERGOLA BUILDER</h1>
                    </div>
                </header>
                <div className="main-wrapper">

                    <div className="pergola-wrapper">
                        {this.state.step >= 1 ?
                        <div className='reset'><button className='reset-btn' onClick={this.radioChangeHandlerReset}><FontAwesomeIcon icon={faRefresh}/></button></div>
                        : null}
                        <img className='pergola' src={images[imageURL]} alt="pergola"/>
                    </div>

                    <div className="button-group">
                        
                        <div className='options-main-wrapper'>
                            <div className='options-sub-wrapper'>
                                <div className='option-wrapper'>
                                    <div className='option-text-wrapper'>
                                        <h3>Hardware Style</h3>
                                    </div>
                                    <div className='button-wrapper'>
                                        <select id="hardware-style" title="Hardware Style" onChange={this.radioChangeHandlerT}>
                                            <option value="none" hidden>Select</option>
                                            <option value="post">Post Mount</option>
                                            <option value="lopro">LO-PRO</option>
                                        </select>
                                    </div>
                                </div>
                                {this.state.step >= 1 ?
                                <div className='option-wrapper'>
                                    <div className='option-text-wrapper'>
                                        <h3>Ground Post and<br/>Beam Connection</h3>
                                    </div>
                                    <div className='button-wrapper'>
                                        <select onChange={this.radioChangeHandlerP}>
                                            {this.state.step === 1 ?<option value="none" hidden>Select</option> : null}
                                            <option value="db">Double Beam</option>
                                            <option value="sb">Single Beam</option>
                                        </select>
                                    </div>
                                </div>
                                : null}
                                {this.state.step >= 2 ?
                                <div className='option-wrapper'>
                                    <div className='option-text-wrapper'>
                                        <h3>Width</h3><h5>(Along House)</h5>
                                    </div>
                                    <div className='button-wrapper'>
                                        <select onChange={this.radioChangeHandlerW}>
                                            {this.state.step === 2 ?<option value="none" hidden>Select</option> : null}
                                            <option value="12w">12 ft</option>
                                            <option value="16w">16 ft</option>
                                            <option value="20w">20 ft</option>
                                            <option value="24w">24 ft</option>
                                            <option value="32w">32 ft</option>
                                            <option value="40w">40 ft</option>
                                        </select>
                                    </div>
                                </div>
                                : null}
                            </div>
                            <div className='options-sub-wrapper'>
                                {this.state.step >= 3 ?
                                <div className='option-wrapper'>
                                    <div className='option-text-wrapper'>
                                        <h3>Depth</h3><h5>(Away From House)</h5>
                                    </div>
                                    <div className='button-wrapper'>
                                        <select onChange={this.radioChangeHandlerD}>
                                            <option value="8">8 ft</option>
                                            <option value="10">10 ft</option>
                                            <option value="12">12 ft</option>
                                            <option value="14">14 ft</option>
                                            <option value="16">16 ft</option>
                                        </select>
                                    </div>
                                </div>
                                : null}
                                {this.state.step >= 3 ?
                                <div className='option-wrapper'>
                                    <div className='option-text-wrapper'>
                                        <h3>Rafter Spacing</h3>
                                    </div>
                                    <div className='button-wrapper'>
                                        <select onChange={this.radioChangeHandlerS}>
                                            <option value="16oc">16 in oc</option>
                                            <option value="24oc">24 in oc</option>
                                        </select>
                                    </div>
                                </div>
                                : null}
                            
                        

                                {this.state.step >= 3 ?
                                <div className='spacer-wrap animateIn2'><div className='spacer'></div></div>
                                : null}

                                {this.state.step >= 3 ?
                                <div className='button-wrapper option animateIn3'>
                                    <Link to="infoPanel" spy={true} smooth={true}><button className='btn mats'>Hardware &<br/>Material List</button><div className='arrow-wrapper'><FontAwesomeIcon icon={faArrowDown}/></div></Link>
                                </div>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>


                {this.state.step >= 3 ?            
                <div id='infoPanel' className="info-panel">
                    <div className='info-head'>
                        <h2>Hardware & Material List</h2>
                    </div>
                    <div className='spacer-wrap'><div className='spacer-black'></div></div>
                    <div className='table-wrapper'>
                        <div className='table-wrapper-sub'>
                        <p>Buy in store and save!<br/>Find a local stocking lumber yard <a href='https://www.patioroofriser.com/local-dealer' target="_blank" rel="noopener">HERE</a>.</p>
                            <table className='hardware-table'>
                                <thead>
                                    <tr className='head'>
                                        <th>Hardware</th>
                                        <th>Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Patio Roof Risers</td>
                                        <td>{hardware.risers}</td>
                                    </tr>
                                    <tr>
                                        <td>Post to Beam Bolts</td>
                                        <td>{hardware.postBolts}</td>
                                    </tr>
                                    <tr>
                                        <td>Bridge Washers</td>
                                        <td>{hardware.bridgeWashers}</td>
                                    </tr>
                                    {/*double beam does not use T braces*/}
                                    {this.state.postType === 'sb' ?
                                    <tr>
                                        <td>PRR T Braces</td>
                                        <td>{hardware.TBraces}</td>
                                    </tr>
                                    : null}
                                    {/*single beam does not use structural lags*/}
                                    {this.state.postType === 'db' ?
                                    <tr>
                                        <td>Structural Lags</td>
                                        <td>{hardware.lags}</td>
                                    </tr>
                                    : null}
                                    <tr>
                                        <td>Post Bases (Optional)</td>
                                        <td>{hardware.postBases}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='cost'>Total Hardware Cost: ${totalHardwareCost.toFixed(2)}</div>                            
                            <a href={hardwareURL} target="_blank" rel="noopener">
                                Click here to view in shop.
                            </a>
                        </div>

                        <div className='table-wrapper-sub'>
                        <p>Lumber lengths reflect the closest standard lengths.<br/>NOT the finished dimensions.</p>
                            <table className='cut-list-table'>
                                <thead>
                                    <tr className='head'>
                                        <th>Lumber</th>
                                        <th>Qty</th>
                                        <th>Cost ea.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Ground Posts<br/><div className='lumdim'>{(this.state.postType === 'db') ? '6' : '4'}"x6"x8'</div></td>
                                        <td>{lumber.posts}</td>
                                        <td>$<input type="number" step="any" placeholder={lumber.postCost} onChange={this.radioChangeHandlerPostCost} className='cost-input'></input></td>
                                    </tr>
                                    <tr>
                                        <td>Ground Beam(s)<br/><div className='lumdim'>{(this.state.postType === 'db') ? '2' : '4'}"x8"x{lumber.beamLength}'</div></td>
                                        <td>{lumber.groundBeam}</td>
                                        <td>$<input type="number" step="any" placeholder={lumber.groundBeamCost} onChange={this.radioChangeHandlerGroundBeamCost} className='cost-input'></input></td>
                                    </tr>
                                    <tr>                        
                                        <td>Roof Beam(s)<br/><div className='lumdim'>{(this.state.hardwareType === 'post') ? '2' : '4'}"x8"x{lumber.beamLength}'</div></td>
                                        <td>{lumber.roofBeam}</td>
                                        <td>$<input type="number" step="any" placeholder={lumber.roofBeamCost} onChange={this.radioChangeHandlerRoofBeamCost} className='cost-input'></input></td>
                                    </tr>
                                    <tr>
                                        <td>Rafters<br/><div className='lumdim'>2"x8"x{lumber.rafterLength}'</div></td>
                                        <td>{lumber.rafters}</td>
                                        <td>$<input type="number" step="any" placeholder={lumber.rafterCost} onChange={this.radioChangeHandlerRafterCost} className='cost-input'></input></td>
                                    </tr>
                                    <tr>
                                        <td>Purlins<br/><div className='lumdim'>2"x2"x{lumber.purlinLength}'</div></td>
                                        <td>{lumber.purlins}</td>
                                        <td>$<input type="number" step="any" placeholder={lumber.purlinCost} onChange={this.radioChangeHandlerPurlinCost} className='cost-input'></input></td>
                                    </tr>
                                    {(this.state.postType === 'db' || this.state.hardwareType === 'post') ?
                                    <tr>
                                        <td>{this.state.postType === 'db' ? 'Diagonal Braces' : ''}{(this.state.postType === 'db' && this.state.hardwareType === 'post') ? <br/> : null}{(this.state.postType === 'db' && this.state.hardwareType === 'post') ? '& ' : ''}{this.state.hardwareType === 'post' ? 'Roof Riser Posts' : ''}<br/><div className='lumdim'>4"x4"x8'</div></td>
                                        <td>{lumber.fourByFour}</td>
                                        <td>$<input type="number" step="any" placeholder={lumber.fourByFourCost} onChange={this.radioChangeHandlerFourByFourCost} className='cost-input'></input></td>
                                    </tr>
                                    : null}

                                </tbody>
                            </table>
                            <div className='cost'>Total Lumber Cost Estimate: ${totalLumberCost.toFixed(2)}</div>
                            <p>Priced for S Dry Douglas Fir (updated 11/28/22)<br/>Lumber pricing may vary greatly.<br/>You can enter your own pricing for bids.</p>
                        </div>
                    </div>
                    <div className='total-cost'>Total Project Cost Estimate: ${(totalLumberCost + totalHardwareCost).toFixed(2)}</div>

                    <div className='info-head'>
                        <h2>Some Info</h2>
                    </div>
                    <div className='spacer-wrap'><div className='spacer-black'></div></div>
                    <div className='info-info-wrapper'>
                        <ul className='info-info'>
                            <li className='info-info-item'>
                                The sizes (width, depth) are refering to covered area. The actual footprint of the
                                structure will be smaller.
                            </li>
                            <li className='info-info-item'>
                                To learn more about the differences between our two styles of hardware
                                (LO-PRO vs Post Mount), and find out which style will best suit your needs
                                visit our primary website <a href="https://www.patioroofriser.com" target="_blank" rel="noopener">here</a>.
                            </li>
                        </ul>
                        <ul className='info-info'>
                            <li className='info-info-item'>
                                Our application is a great place to start, but make sure to always field verify all aspects of your project.
                            </li>
                            <li className='info-info-item'>
                                You aren't locked in to these sizes / designs with Patio Roof Risers.
                                Visit our <a href="https://www.patioroofriser.com/shop" target="_blank" rel="noopener">SHOP</a> to get exactly
                                what you need for your project and be creative!
                            </li>
                        </ul>
                    </div>

                    <div className='info-head'>
                        <h2>Disclaimer</h2>
                    </div>
                    <div className='spacer-wrap'><div className='spacer-black'></div></div>
                    <div className='info-wrapper'>
                        <p className="disclaimer">
                            YOUR USE OF THE 3D MODELS AND PLANS MUST BE REVIEWED AND APPROVED BY QUALIFIED PROFESSIONALS
                            FAMILIAR WITH ALL APPLICABLE BUILDING CODES. THE SPECIFIC DESIGN AND LOCATION OF THE
                            STRUCTURE, THE BUILDING MATERIALS USED, THE QUALITY OF CONSTRUCTION, THE CONDITION OF
                            THE SOILS OR SUBSTRATES INVOLVED, AND SITE-SPECIFIC LOADING DUE TO THE PARTICULAR
                            CHARACTERISTICS OF POTENTIAL IMPACT EVENTS SUCH AS EARTHQUAKES AND HIGH VELOCITY WINDS
                            WILL AFFECT THE STRENGTH AND SAFETY OF THE STRUCTURE OR PROJECT.
                            THE PLANS ARE NOT A SUBSTITUTE FOR PROFESSIONAL JUDGEMENT. A QUALIFIED PROFESSIONAL
                            MUST CONFIRM THAT THE PLANS MAY BE USED AT YOUR SITE BY REFERRING TO ALL APPLICABLE
                            BUILDING CODES, AMENDMENTS AND ALL OTHER RELEVANT INFORMATION, INCLUDING WITHOUT
                            LIMITATION THE CURRENT PATIO ROOF RISER CATALOGS AND WEBSITES. DUE TO THE LARGE
                            VARIETY OF POTENTIAL APPLICATIONS FOR THE PLANS, COMPANY SHALL NOT BE LIABLE IN ANY
                            MANNER WHATSOEVER FOR THE RESULTS OBTAINED THROUGH THE USE OF THE PLANS. THE PLANS
                            ARE INTENDED TO BE USED ONLY IN CONJUNCTION WITH COMPANY PRODUCTS.
                        </p>
                    </div>
                </div>
                : null}

                {this.state.step >= 3 ? 
                <div className='footer'>
                    <h4>Copyright © 2022 Patio Roof Riser - Built by <a href="https://kadonengle.com/" target="_blank" rel="noopener">KE</a></h4>
                </div>
                : null}

            </div>
        );
    }
}




  // ========================================
  
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);